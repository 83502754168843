

import {gmapApi} from 'vue2-google-maps'
import * as geolib from 'geolib';


const stringOptions = ([
  { text: 'BASALA', value: 'BASALA' }, { text: 'BENUA', value: 'BENUA' }, { text: 'BUKE', value: 'BUKE' }, { text: 'KONDA', value: 'KONDA' }, { text: 'LAEYA', value: 'LAEYA' },
  { text: 'LALEMBUU', value: 'LALEMBUU' },
  { text: 'LANDONO', value: 'LANDONO' },
  { text: 'PALANGGA', value: 'PALANGGA' },
  { text: 'MOWILA', value: 'MOWILA' },
  { text: 'KOLONO TIMUR', value: 'KOLONO TIMUR' },
  { text: 'SABULAKOA', value: 'SABULAKOA' },
  { text: 'ANDOOLO BARAT', value: 'ANDOOLO BARAT' },
  { text: 'ANDOOLO', value: 'ANDOOLO' },
  { text: 'ANGATA', value: 'ANGATA' },
  { text: 'BAITO', value: 'BAITO' },
  { text: 'PALANGGA SELATAN', value: 'PALANGGA SELATAN' },
  { text: 'RANOMEETO', value: 'RANOMEETO' },
  { text: 'RANOMEETO BARAT', value: 'RANOMEETO BARAT' },
  { text: 'WOLASI', value: 'WOLASI' },
  { text: 'KOLONO', value: 'KOLONO' },
  { text: 'LAINEA', value: 'LAINEA' },
  { text: 'LAONTI', value: 'LAONTI' },
  { text: 'MORAMO', value: 'MORAMO' },
  { text: 'MORAMO UTARA', value: 'MORAMO UTARA' },
  { text: 'TINANGGEA', value: 'TINANGGEA' },
  
])


// const stringOptions = [
//   'BASALA', 'BENUA', 'BUKE', 'KONDA', 'LAEYA', 'LALEMBUU', 'LANDONO', 'PALANGGA', 'MOWILA', 'KOLONO TIMUR',
//   'SABULAKOA', 'ANDOOLO BARAT', 'ANDOOLO', 'ANGATA', 'BAITO', 'PALANGGA SELATAN', 'RANOMEETO', 'RANOMEETO BARAT',
//   'WOLASI', 'KOLONO', 'LAINEA', 'LAONTI', 'MORAMO', 'MORAMO UTARA', 'TINANGGEA'
// ]

export default {
  data() {
    return {
      // Tombol Modal
      tambah: false,
      edit: false,
      lihat: false,
      modal_lokasi: false,
      mdl_hapus: false,

      // Tombol Save Modal
      simpan1: false,
      simpan2: false,
      //
	  options: stringOptions,

      markers: [{
        position: {
          lat: -4.250462,
          lng: 122.1291645
        }
      }],

      form : {
        id: '',
        subject_pajak_id: '',
        nik: '',
        nama: '',
        alamat: '',
        kelurahan: '',
        kecamatan: '',
        rwrt: '',
        no_telp: '',
        file: '',
      },
      lokasi: [],
      centroid: null,
      //
      file_old: "",
      file_path: this.$store.state.url.URL_APP + "uploads/",

      no: 0,
      id: '',
      url: {
        GET_URL: this.$store.state.url.URL_APP + "api/v1/web_zona_sanggah/"
      },
      list_data: [],

      page_first: 1,
      page_last: 0,
      cari_value: "",

    }
  },
  methods: {
  
  setModel (val) {
      this.form.kecamatan = val

    },

    mapping: function(data) {

      var tampung = [];

      for(var i = 0; i < data.length; i++) {
          var obj = data[i];

          tampung.push({"lat":parseFloat(obj.lat),"lng":parseFloat(obj.lng)});
      }

      return tampung;

    },


    getView: function() {
      this.cek_load_data = true;
      fetch(this.url.GET_URL + "view", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            data_ke: this.page_first,
            cari_value: this.cari_value
          })
        })
        .then(res => res.json())
        .then(res_data => {

          console.log(res_data)
          
          this.list_data = res_data[0].data1;
          this.page_last = res_data.lengthy;
          this.cek_load_data = false;
          
          // 


        });
    },


    calculatePolygonCentroid() {
      const coordinates = this.lokasi.map((point) => ({
        latitude: point.lat,
        longitude: point.lng,
      }));

      const centroid = geolib.getCenter(coordinates);

      this.centroid = {
        lat: centroid.latitude,
        lng: centroid.longitude,
      };
    },


    addData: function() {

      this.calculatePolygonCentroid();

      console.log(this.centroid);

      var formData = new FormData();

      formData.append("subject_pajak_id", this.form.subject_pajak_id);
      formData.append("nik", this.form.nik);
      formData.append("nama", this.form.nama);
      formData.append("alamat", this.form.alamat);
      formData.append("kelurahan", this.form.kelurahan);
      formData.append("kecamatan", this.form.kecamatan);
      formData.append("rwrt", this.form.rwrt);
      formData.append("no_telp", this.form.no_telp);
      formData.append("file", this.form.file);


      formData.append("lokasi", JSON.stringify(this.lokasi));

      formData.append("marker", JSON.stringify(this.centroid));


      fetch(this.url.GET_URL + "addData", {
        method: "POST",
        headers: {
          authorization: "kikensbatara " + localStorage.token
        },
        body: formData
      }).then(res_data => {
        this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
        this.getView();
        this.tambah = false;
      })
    },

    editData: function() {

      var formData = new FormData();

      formData.append("id", this.form.id);
      formData.append("subject_pajak_id", this.form.subject_pajak_id);
      formData.append("nik", this.form.nik);
      formData.append("nama", this.form.nama);
      formData.append("alamat", this.form.alamat);
      formData.append("kelurahan", this.form.kelurahan);
      formData.append("kecamatan", this.form.kecamatan);
      formData.append("rwrt", this.form.rwrt);
      formData.append("no_telp", this.form.no_telp);
      formData.append("file", this.form.file);
      formData.append("file_old", this.file_old);

      formData.append("lokasi", JSON.stringify(this.lokasi));
      formData.append("marker", JSON.stringify(this.centroid));


      fetch(this.url.GET_URL + "editData", {
        method: "POST",
        headers: {
          authorization: "kikensbatara " + localStorage.token
        },
        body: formData
      }).then(res_data => {
        // this.alertku("success", "Sukses merubah data");
        this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
        this.getView();
        this.edit = false;
      });
    },

    selectData: function(data, string) {

      if(string == "edit"){
        this.edit = true;

        this.form = {
          id: data.id,
          subject_pajak_id: data.subject_pajak_id,
          nik: data.nik,
          nama: data.nama,
          alamat: data.alamat,
          kelurahan: data.kelurahan,
          kecamatan: data.kecamatan,
          rwrt: data.rwrt,
          no_telp: data.no_telp,
          file: data.file,
        };
        this.lokasi = data.lokasi;
        this.file_old = data.file;
      }
      else if(string == "lokasi"){
        this.modal_lokasi = true;

        this.form = {
          
          kecamatan: data.kecamatan,
        }
        this.lokasi = data.lokasi;
        this.centroid = data.marker
        
        
      }
      else if(string == "hapus"){
        this.mdl_hapus = true;

        this.form = {
          id: data.id,
          subject_pajak_id: data.subject_pajak_id,
          nik: data.nik,
          nama: data.nama,
          alamat: data.alamat,
          kelurahan: data.kelurahan,
          kecamatan: data.kecamatan,
          rwrt: data.rwrt,
          no_telp: data.no_telp,
          file: data.file,
        };
        this.lokasi = data.lokasi;
        this.file_old = data.file;

        
      }
      else if (string == "lihat") {
        this.lihat = true;

        this.form.file = data.file;
        
      }


      // CKEDITOR.instances.editor1.setData(data.isi);
    },


    removeData: function(idnya, file) {
      fetch(this.url.GET_URL + "removeData", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: JSON.stringify({
          id: idnya,
          file: file
        })
      }).then(res_data => {
        this.getView();
        this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
        this.mdl_hapus = false;
      });
    },

    simulateProgress(number) {
      // we set loading state
      this[`simpan${number}`] = true
      // simulate a delay
      setTimeout(() => {
        // we're done, we reset loading state
        this[`simpan${number}`] = false
      }, 3000)
    },

    cari_data: function() {

      this.page_first = 1;
      this.getView();
    },

    Notify : function(message, positive, icon){
            this.$q.notify({
              message: message,
              color: positive,
              icon: icon,
              position : 'top',
              timeout: 500,
            })
          },

    alertku: function(type, title) {
      this.$swal({
        type: type,
        title: title,
        showConfirmButton: false,
        timer: 800
      });
    },

    onFileSelected: function(event) {
      this.form.file = event.target.files[0];

    },

    btn_prev: function() {
      this.cek_load_data = true;
      if (this.page_first > 1) {
        this.page_first--
      } else {
        this.page_first = 1;
        this.no -= 10;
      }
      this.getView();
    },

    btn_next: function() {

      if (this.page_first >= this.page_last) {
        this.page_first == this.page_last
      } else {
        this.page_first++;
        this.no += 10;
      }
      this.getView();
    },

    addLokasi: function () {
     this.lokasi.push({ lat: 0 , lng: 0});
    },
    hapusLokasi: function () {
     this.lokasi.splice(-1,1);
    }

  },

  computed: {
    google: gmapApi
  },
  mounted() {

    this.getView();

  }
}
